

.disabled-link {
    pointer-events: none;
    opacity: 0.6;
    text-decoration: none;
}


/*? datatable serializacion */
.container-fluid .row .col-12 .card {

    .dataTable {
        overflow-x: scroll;
    }

    .dataTable::-webkit-scrollbar {
        width: 4px;
        height: 1rem;
        border-radius: 5px;
    }

    .dataTable::-webkit-scrollbar-track {
        background: transparent;
    }

    .dataTable::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #3283f6;
    }


}


/*? cursor pointer style */
.pointer{
    cursor: pointer;
}

.d-none{
    display: none;
}

/*? btn class close form serializacion */

.btn-close-config{
    background-color: #fcc015 !important;
    border-color: #fcc015 !important;
}

/*? scroll-x datatable configuraciones */

#datatable_wrapper{
    .row:nth-child(2n){

        overflow-x: scroll;
    }
        
}

/*? select2 serializacion */

.select2-container--default .select2-selection--single{
    display: block !important;
    width: 100% !important;
    color: #94a0ad !important;
    background-color: #3b4651 !important;
    background-clip: padding-box !important;
    border: 1px solid #424e5a !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    border-radius: 0.2rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    font-size: .875rem;
    font-weight: 400;
    margin-bottom: .75rem!important;
    height: 2.5rem !important;
    display: flex !important;
    align-items: center;
}

.select2-selection__rendered{
    color: #94a0ad !important;
}   

.fecha-mt{
    margin-top: 1.2rem !important;
}

/*? editar overflow-scroll  */

.hide_scroll-y::-webkit-scrollbar{width:0px;border:none;}
.hide_scroll-y::-webkit-scrollbar-thumb{background-color:transparent;}

/*? Msgs div */

#msgs{
    position: absolute;
    top: 50px;
    right: 30px;
}

/*? Smothscroll checkbox*/
.switchery{
    width: 28px !important;
    height: 15px !important;
}

.switchery > small {
    height: 15px !important;
    width: 16px !important;
}
/*? Checkbox form section*/

.checkbox_form_section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .5rem 0;
}